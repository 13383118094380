@value variables: "../../variables.css";
@value mobile-threshold-width from variables;

.logoutButton {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
    color: var(--dark-grey);
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    transition: 0.5s;
    padding: 10px;
    padding-left: 15px;
    border-radius: 5px;
}

.draverIcon {
    margin-right: 20px;
    fill: var(--white);
}

.logoutButton:hover {
    background-color: var(--hover-over-white);
}
@media (max-width: mobile-threshold-width) {
    .logoutButton {
        padding-left: 25px;
        color: var(--dark-grey);
        opacity: 0.7;
    }

    .logoutButton:active {
        background-color: var(--hover-over-white);
    }
}
