.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
}

.text {
    width: 100%;
    text-align: left;
}
