.notification {
    position: relative;
    display: flex;
    border-radius: 5px;
    background-color: var(--notfication-green);
    color: var(--dark-grey);
    font-size: 14px;
    line-height: 19px;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
    z-index: 99;
}

.error {
    background-color: var(--notification-red);
}

.closeButton {
    position: absolute;
    top: -15px;
    right: -10px;
}
