@value variables: "../../../variables.css";
@value mobile-threshold-width from variables;

input[type='checkbox'].checkbox {
    appearance: none;
    border: 1.2px solid var(--light-blue);
    border-radius: 1.5px;
    width: 17px;
    height: 17px;
    display: inline-block;
    margin: auto 0px;
    position: relative;
    cursor: pointer;
}

input[type='checkbox'].checkbox:checked {
    background-color: var(--light-blue);
    background-image: url('../../../static/images/icons/check.svg');
    background-size: 13px 13px;
    background-position: center;
    border: 1.2px solid var(--light-blue);
}

.label {
    padding-left: 5px;
    cursor: pointer;
}

@media (max-width: mobile-threshold-width) {
    input[type='checkbox'].checkbox {
        background-color: var(--white);
        border: 1.2px solid var(--light-grey2);
    }
}
