@value variables: "../../../variables.css";
@value mobile-threshold-width from variables;

.container{
    display: flex;
    flex-wrap: wrap;
    gap:10px;
    justify-content:space-around;
    margin-bottom: 25px;
}


@media (max-width: mobile-threshold-width) {
    .divider{
        display: none;
    }
}