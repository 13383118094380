@value variables: "../../variables.css";
@value mobile-threshold-width from variables;

.container {
    font-size: 13px;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 100px 7%;
    width: 50%;
    height: auto;
    box-shadow: 0px 0px 30px rgba(54, 56, 65, 0.05);
    background: var(--white);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    letter-spacing: 0.02em;
}

.logo {
    display: none;
}

.textInput {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    line-height: normal;
    letter-spacing: 0.02em;
    color: var(--mid-tone-grey2);
}

.textInput input {
    font-size: 14px;
    letter-spacing: 0.02em;
    font-size: 14px;
    margin-bottom: 1px;
}

.textInput .passwordLabel {
    display: flex;
    align-content: space-between;
    width: 100%;
}

.resetPasswordButton {
    color: var(--light-blue);
    text-align: right;
    font-weight: 600;
    cursor: pointer;
    margin-left: auto;
}

.title {
    text-align: left;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 22px;
}

.marginForButton {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.container .loginButton {
    text-transform: none;
    padding: 10px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.02em;
    box-shadow: 0px 0px rgba(0, 0, 0, 0);
}

@media (max-width: mobile-threshold-width) {
    .container {
        width: 100%;
        padding: 30px 20px;
    }

    .logoContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 35vh;
        height: 100%;
    }

    .logo {
        display: block;
        height: 100px;
        width: 100px;
    }

    .title {
        display: none;
    }

    .container .marginForButton {
        height: 110px;
        display: flex;
        flex-direction: column;
        align-content: center;
        margin-top: auto;
    }

    .loginButton {
        width: 100%;
    }

    .textInput {
        margin-top: 20px;
    }
}
