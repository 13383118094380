.textFieldContainer {
    width: 100%;
    margin-top: 19px;
    font-size: 13px;
    letter-spacing: 0.02em;
    color: var(--mid-tone-grey);
    text-align: left;
}

.textFieldContainer.noTopMargin {
    margin-top: 0;
}

.textFieldContainer input,
.textFieldContainer textarea,
.textFieldContainer select {
    font-size: 14px;
}

.textFieldContainer .textField {
    border: 1px solid var(--light-grey2);
    border-radius: 5px;
    padding: 3px 0 0 15px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    min-height: 40px;
    width: 100%;
}

.textFieldContainer .textInput {
    margin: 0;
    width: 100%;
    padding-right: 4px;
    padding-bottom: 3px;
}
