@value variables: "../../../variables.css";
@value mobile-threshold-width from variables;

body .actionListPanel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 40px;
}

.actionListPanel .topBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.secondButton {
    margin-left: 10px;
}

.secondButton button {
    width: auto;
}

@media (max-width: mobile-threshold-width) {
    body .actionListPanel {
        margin-bottom: 20px;
    }
    .addIssueButton {
        position: fixed;
        z-index: 998;
        bottom: 20px;
        width: calc(100vw - 40px);
    }
}
