.label {
    display: flex;
    flex-direction: row;
    margin: 20px;
    position: sticky;
    top: 0;
    background: var(--white);
    z-index: 10;
}

.label .cell {
    font-size: 13px;
    letter-spacing: 0.02em;
    line-height: 18px;
    color: var(--mid-tone-grey);
    display: flex;
    align-items: center;
}

.label th:first-of-type {
    padding-left: 20px;
}

.label th {
    padding-left: 50px;
}

body .header-column-cell-content {
    position: relative;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
}

body .header-column-cell-content .header-column-cell-content__filter {
    position: absolute;
    right: 48px;
    top: 49px;
}
