@value variables: "../../../variables.css";
@value fourth-table-shrink-width from variables;

.container {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    gap: 32px;
}

.text {
    font-size: 16px;
    font-weight: 600;
}

@media (max-width: fourth-table-shrink-width) {
    .container {
        flex-direction: column;
        gap: 4px;
    }

    .text {
        padding-left: 8px;
        padding-right: 8px;
        font-size: 12px;
        font-weight: 600;
    }
}

