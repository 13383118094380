:root {
    --white: #ffffff;
    --dark-grey: #3f4146;
    --mid-tone-grey: #6a6f7a;
    --mid-tone-grey2: #8d939e;
    --light-grey1: #ebf0f5;
    --light-grey2: #dbe2ea;
    --light-grey3: #fbfcfd;
    --light-blue: #047beb;
    --dark-blue: #056bca;
    --green: #63dba2;
    --red: #ff6969;
    --effect-style1: 0px 0px 20px #f3f6f9;
    --effect-style2: 0px 0px 20px #ebf0f5;
    --login-img-bg-color: rgba(4, 123, 235, 0.85);
    --hover-over-white: rgba(0, 0, 0, 0.07);
    --hover-over-blue: #004d95;
    --negative-color: #eb5757;
    --hover-negative-color: #ab4848;
    --positive-color: #2bd282;
    --border-alert-color: #f5c6cb;
    --bg-alert-color: #f8d7da;
    --text-alert-color: #721c24;
    --image-border: #dbe2ea;
    --nonactive-tabs: #b4d7f9;
    --notification-red: #fff0f0;
    --notfication-green: #defce8;
    --overdue: #ffdead;
    --overdue-hover: #ffdba0;
    --critical: #ff9587;
    --critical-hover: #ffaea3;
    --backdrop: rgba(0, 0, 0, 0.6);
}

body {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
    font-family: Open Sans !important;
}
