@value variables: "../../../variables.css";
@value mobile-threshold-width from variables;

@media (max-width: mobile-threshold-width) {
    .issueImage {
        width: 100%;
    }
}
.overdue {
    background-color: var(--overdue);
}

.overdue:hover {
    background-color: var(--overdue-hover) !important;
}

.critical {
    background-color: var(--critical);
}

.critical:hover {
    background-color: var(--critical-hover) !important;
}
