.elementName {
    margin: 10px 0;
}

.elementName:first-of-type {
    margin-top: 0;
}

.elementName:last-of-type {
    margin-bottom: 0;
}
