@value variables: "../../../variables.css";
@value mobile-threshold-width from variables;

body .listTitle {
    font-size: 22px;
    line-height: 40px;
    font-style: normal;
    font-weight: bold;
    color: var(--dark-grey);
    letter-spacing: 0.02em;
    display: flex;
    justify-content: space-between;
}

@media (max-width: mobile-threshold-width) {
    body .listTitle {
        margin-top: 30px;
        margin-bottom: 0;
    }
}

@media (max-height: 500px) {
    body .listTitle {
        margin-top: 15px;
    }
}
