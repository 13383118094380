@value variables: "../../../../../../variables.css";
@value mobile-threshold-width from variables;

@media (max-width: mobile-threshold-width) {
    .column {
        display: flex;
        align-items: center;
    }

    .issueImage {
        height: 50px;
        width: 50px;
        border-radius: 4px;
        border: 1px solid var(--image-border);
        background: var(--white);
        margin-right: 15px;
    }

    .default {
        padding: 16.5px;
    }

    .title {
        font-size: 13px;
        line-height: 18px;
        color: var(--dark-grey);
        font-weight: 600;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 150px;
    }

    .text {
        font-size: 13px;
        line-height: 18px;
        color: var(--dark-grey);
        margin: 0;
        margin-top: 8px;
    }

    .textContainer {
        display: flex;
        flex-direction: column;
    }
}
