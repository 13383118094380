.burgerButton {
    display: block;
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 10;
}

.burgerButton .line {
    display: block;
    position: relative;
    margin: 4px;
    width: 15px;
    height: 1px;
    background-color: var(--dark-grey);
}
