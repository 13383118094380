.checkbox {
    padding: 0 5px;
}

.checkbox .root {
    width: fit-content;
    color: var(--mid-tone-grey2);
}

.checkbox .label {
    font-size: 13px;
    color: var(--dark-grey);
}

.checked {
    color: var(--light-blue) !important;
}

.alertTriangle {
    margin-right: 11px;
    margin-left: 13px;
}

.title {
    width: 100%;
    font-size: 13px;
    letter-spacing: 0.02em;
    color: var(--mid-tone-grey);
    text-align: left;
    margin-top: 20px;
}
