@value variables: "../../variables.css";
@value mobile-threshold-width from variables;

.detailsBox {
    width: 100%;
    max-height: calc(100vh - 178px - 20px);
    overflow: auto;
    margin-top: 30px;
}

.buttons {
    position: absolute;
    top: 104px;
    right: 100px;
    display: flex;
}

.detailsBox .btn {
    width: 170px;
    height: 45px;
    display: block;
}

.buttons .saveBtn {
    margin-left: 20px;
}

.buttons .deleteBtn {
    border: 1px solid var(--dark-grey);
    color: var(--dark-grey);
    background: white;
}

.buttons .changePasswordBtn {
    border: 1px solid var(--dark-grey);
    color: var(--dark-grey);
    background: white;
    margin-left: 20px;
}

.textField {
    width: 100%;
    display: flex;
}

.userDetails {
    display: flex;
    flex-direction: column;
    padding-top: 6px;
    padding-bottom: 25px;
    max-width: 520px;
    width: 90%;
    margin: 0 auto;
}

@media (max-width: mobile-threshold-width) {
    body .detailsBox {
        box-shadow: none;
        margin-top: 0;
    }

    .userDetails {
        width: 100%;
    }

    .buttons {
        position: fixed;
        width: 100%;
        right: auto;
        bottom: 0;
        top: auto;
        flex-direction: column-reverse;
        background: var(--light-grey3);
        z-index: 10;
        margin: auto -4px;
        padding-bottom: 30px;
    }

    .detailsBox .btn {
        width: calc(100vw - 40px);
        height: 45px;
        display: block;
    }

    .btn.saveBtn {
        margin-left: 0px;
        margin-bottom: 15px;
    }

    .buttons .changePasswordBtn {
        margin-left: 0px;
        margin-bottom: 15px;
    }
}
