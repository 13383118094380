body .header-column-cell-filter {
    position: absolute;
    left: 0;
    top: 50px;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    z-index: 1;
}

.header-column-cell-filter .header-column-cell-filter__content {
    padding: 20px;
    background-color: var(--white);
}
