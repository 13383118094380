.fileDropzone {
    width: 100%;
    margin-top: 20px;
}

.fileDropzone header {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: var(--mid-tone-grey);
    margin-bottom: 8px;
}

.fileDropzone .content {
    height: 80px;
    border: 1px dashed var(--light-grey2);
    border-radius: 5px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    outline: none;
    cursor: pointer;
}
.fileDropzone .images {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
}
