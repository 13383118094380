@value variables: "../../../variables.css";
@value mobile-threshold-width from variables;

.backNav {
    font-size: 11px;
    line-height: 15px;
    display: inline-flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: var(--dark-grey);
}

.backNav:hover {
    text-decoration: underline;
    cursor: pointer;
}

.backNav .backArrow {
    font-size: inherit;
}

.navText {
    display: inline-block;
    margin-left: 5px;
}

.hrInBackNav {
    display: none;
}

@media (max-width: mobile-threshold-width) {
    .backNav {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 24px;
    }

    .backNav .navText {
        display: none;
    }

    body .titleInBackNav {
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        margin-top: 0;
    }

    .hrInBackNav {
        display: block;
        border: 1px solid var(--light-grey2);
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-top: 20px;
        margin-bottom: 0;
    }
}
