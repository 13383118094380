@value variables: "../../../variables.css";
@value mobile-threshold-width from variables;

.list {
    border-radius: 5px;
    background: var(--white);
    box-shadow: var(--effect-style1);
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 2px;
    height: calc(100vh - 317px);
}

.list td,
.list th {
    padding-right: 0;
    padding-left: 25px;
}

.displayMobile {
    display: none;
}

@media (max-width: mobile-threshold-width) {
    .list {
        box-shadow: unset;
        background: transparent;
        height: 100%;
        max-height: calc(100vh - 220px);
        margin-top: 25px;
    }

    .noDisplayMobile {
        display: none;
    }

    .displayMobile {
        display: block;
    }

    .paddingForCard {
        padding-bottom: 15px;
    }
}

@media (max-width: mobile-threshold-width) {
    .list {
        height: calc(100vh - 300px);
    }
}
