@value variables: "../../../variables.css";
@value second-table-shrink-width from variables;
@value mobile-threshold-width from variables;

.drawerItems {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 240px;
    font-weight: 600;
}

.line {
    width: calc(100% - 40px);
    max-width: 200px;
    height: 0;
    opacity: 0.25;
    border: 1px solid #ffffff;
}

@media (max-width: second-table-shrink-width) {
    .drawerItems {
        width: 210px;
    }
}

.drawerItems .draverIcon {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    fill: var(--white);
}

.onlyMobile {
    display: none;
}

@media (max-width: mobile-threshold-width) {
    .drawerItems {
        width: 70vw;
        padding-top: 20px;
        background-color: white;
    }

    .drawerItems .draverIcon {
        width: 20px;
        height: 20px;
        margin-right: 20px;
        filter: invert(41%) sepia(81%) saturate(4497%) hue-rotate(195deg)
            brightness(96%) contrast(97%);
        border: 1px solid #047beb;
    }

    .onlyMobile {
        display: block;
        margin-bottom: 20px;
        margin-top: auto;
    }

    .line {
        border-color: var(--mid-tone-grey2);
        width: 100%;
    }
}

@media (max-height: 600px) {
    .drawerItems {
        padding-top: 5px;
    }
}
