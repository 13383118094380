@value variables: "../../../../../../variables.css";
@value mobile-threshold-width from variables;

@media (max-width: mobile-threshold-width) {
    .cardHeader {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-left: 0;
    }

    .status {
        margin-right: 5px;
        margin-bottom: 15px;
    }

    .date {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 13px;
        line-height: 18px;
        color: var(--dark-grey);
    }
}
