@value variables: "../../../../variables.css";
@value first-table-shrink-width from variables;
@value second-table-shrink-width from variables;

.listItem {
    cursor: pointer;
    margin: 20px;
    border-top: 1px solid var(--light-grey1);
}

body .listItem:hover {
    background-color: var(--light-grey3);
}

.listItem .cell {
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px !important;
    letter-spacing: 0.02em;
    line-height: 18px;
    font-style: normal;
    color: var(--dark-grey) !important;
    height: 50px;
}

.listItem .cell:first-of-type {
    padding-left: 20px;
}

.listItem .cell.elementName,
.listItem .cell.issueDescription,
.listItem .cell.warranty,
.listItem .cell.status,
.listItem .cell.branchName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.listItem .cell.elementName {
    max-width: 20ch;
}

.listItem .cell.issueDescription {
    max-width: 350px;
}

.listItem .cell.status {
    max-width: 145px;
}

.listItem .cell.branchName {
    max-width: 30ch;
}

@media (max-width: first-table-shrink-width) {
    .listItem .cell.elementName {
        max-width: 20ch;
    }

    .listItem .cell.branchName {
        max-width: 15ch;
    }

    .listItem .cell.issueDescription {
        max-width: 180px;
    }

    .listItem .cell {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: second-table-shrink-width) {
    .listItem .cell.elementName {
        max-width: 15ch;
    }

    .listItem .cell.branchName {
        max-width: 10ch;
    }

    .listItem .cell {
        padding-left: 10px;
        padding-right: 10px;
    }

    .listItem .cell.issueDescription {
        max-width: 10ch;
    }
}

.bin {
    width: 20px;
    height: 20px;
}
