@value variables: "../../variables.css";
@value mobile-threshold-width from variables;

.textField {
    width: 100%;
    display: flex;
}

.errorText {
    color: var(--red);
}
