.mapContainer {
    width: 100%;
    height: calc(100vh - 200px);
    min-height: 300px;
    max-height: 620px;
    border: 2px solid #dddddd;
}

.buttonsRow {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}
