@value variables: "../../variables.css";
@value mobile-threshold-width from variables;

.root {
    position: relative;
    padding-top: 25px;
}

.taskDetailsBox {
    width: 100%;
    max-height: calc(100vh - 178px - 25px);
    overflow: auto;
}

.buttons {
    position: absolute;
    transform: translateY(-100%);
    top: 0;
    right: 0;
    display: flex;
}

.taskDetailsBox .btn {
    width: 170px;
    height: 45px;
    display: block;
}

.buttons .saveBtn {
    margin-left: 20px;
}

.buttons .deleteBtn {
    border: 1px solid var(--dark-grey);
    color: var(--dark-grey);
    background: white;
}

.taskDetailsBox .issueDetailsBox__row:not(:first-of-type) {
    margin-top: 30px;
}

.taskDetailsBox .taskDetailsBox .textField {
    width: 100%;
    display: flex;
}

.taskDetailsBox .searchIcon {
    color: var(--mid-tone-grey2);
    margin-right: 1em;
    margin-bottom: 4px;
}

.taskDetailsBox .picker {
    margin-top: 1em;
}

.taskDetailsBox .datesInputContainer {
    margin-top: 0;
    width: 30%;
}

.taskDetailsBox .fieldTitle {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.4px;
    color: var(--mid-tone-grey);
    margin-bottom: 8.5px;
    line-height: 14px;
}

.taskDetailsBox .icon {
    display: flex;
    flex-direction: column;
}

.taskDetailsBox .icon .fieldTitle {
    margin-bottom: 15px;
}

.taskDetailsBox-generate-qrcodeBtn {
    margin-top: 30px;
    margin-left: auto;
}

.taskDetails {
    display: flex;
    flex-direction: column;
    width: 520px;
    margin: 0 auto;
}

.taskDetails:last-of-type {
    padding-bottom: 2rem;
}

.taskDetails--icon {
    margin-right: 1em;
    margin-bottom: 4px;
}

.issueDetailsSearch-icon {
    margin-bottom: 4px;
}

.separator {
    border: 1px solid var(--light-grey1);
    margin-top: 25px;
    margin-bottom: 6px;
}

@media (max-width: mobile-threshold-width) {
    body .taskDetailsBox {
        box-shadow: none;
    }

    .taskDetails {
        width: 100%;
        margin-top: -15px;
    }

    .buttons {
        position: fixed;
        transform: initial;
        width: 100%;
        right: auto;
        bottom: 0;
        top: auto;
        flex-direction: column-reverse;
        background: var(--light-grey3);
        z-index: 10;
        padding-bottom: 30px;
    }

    .taskDetailsBox .btn {
        width: calc(100vw - 40px);
        height: 45px;
        display: block;
    }

    .btn.saveBtn {
        margin-left: 0px;
        margin-bottom: 15px;
    }
}
