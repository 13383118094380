.navBar {
    background: var(--light-blue);
    position: relative;
    top: 0;
    bottom: 0;
    min-height: calc(100vh - 66px);
    height: 100%;
}

@media (max-width: 610px) {
    .navBar {
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 1001;
    }
}
