.issue-name {
    display: flex;
    width: 100%;
    align-items: center;
}

.issue-name .icon {
    height: 30px;
    width: 30px;
    margin-right: 15px;
    border-radius: 5px;
    border: 1px solid var(--light-grey2);
    background-color: var(--white);
    object-fit: cover;
}

.issue-name .icon.default {
    padding: 7px;
}

.issue-name .nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
