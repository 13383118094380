@value variables: "../../../variables.css";
@value mobile-threshold-width from variables;

.listA {
    margin-top: 25px;
}

@media (max-width: mobile-threshold-width) {
    .issueImage {
        width: 100%;
    }
}
