.container {
    width: 100%;
    margin-top: 19px;
    font-size: 13px;
    letter-spacing: 0.02em;
    color: var(--mid-tone-grey);
    text-align: left;
}


.container a {
    font-size: 14px;
}

.container .showPhone {
    border: 1px solid var(--light-grey2);
    border-radius: 5px;
    padding: 3px 0 0 15px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    width: 100%;
}

.editButton {
    height: 36px !important;
    margin-top: -3px !important;
}

.error {
    color: var(--negative-color);
    margin-block-start: 5px;
    margin-block-end: 0;
    font-size: 13px;
    letter-spacing: 0.02em;
}
