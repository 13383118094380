@value variables: "../../../../../variables.css";
@value mobile-threshold-width from variables;

@media (max-width: mobile-threshold-width) {
    .card {
        position: relative;
        background-color: var(--white);
        border-radius: 5px;
        margin-bottom: 15px;
        padding: 15px;
        display: flex;
        flex-direction: column;

        box-shadow: var(--effect-style2);
    }

    .bin {
        align-self: flex-end;
    }
}
