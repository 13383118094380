.userName {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    position: relative;
}

.userName p {
    font-size: 13px;
    line-height: 18px;
    color: var(--dark-grey);
    letter-spacing: 0.02em;
    margin-right: 6px;
    margin-left: 16px;
}

.userName .expandClickable {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.userName .userIcon {
    color: var(--light-blue);
}

.userName .expandIcon {
    color: var(--light-blue);
}

.userName .menu {
    position: absolute;
    width: 100%;
    right: 0;
    top: 45px;
    border: 1px solid var(--light-grey1);
    border-radius: 5px;
    background: var(--white);
}
