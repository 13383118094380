@value variables: "../../../variables.css";
@value mobile-threshold-width from variables;

.datePickerContainer {
    margin-top: 19px;
}
.datePicker {
    height: 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1px solid var(--light-grey2);
    border-radius: 4px;
    background-color: var(--white);
    color: var(--dark-grey);
}

.input {
    border: transparent;
    height: 100%;
    width: 100%;
    outline: none;
    font-size: 14px;
    line-height: 19px;
    color: inherit;
    background-color: var(--white);
}

.calendarIcon {
    margin: 9px 15px;
    filter: invert(65%) sepia(13%) saturate(253%) hue-rotate(180deg)
        brightness(88%) contrast(86%);
}

.dateLabel {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: var(--mid-tone-grey);
    margin-bottom: 5px;
}

.disabled {
    color: var(--mid-tone-grey);
}

.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.datePickers {
    width: auto;
    min-width: 48%;
}

@media (max-width: mobile-threshold-width) {
    .datePickers {
        min-width: 100%;
    }
}
