.container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
}
.container .swapButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 40px;
    height: 40px;
    padding: 14px;
    margin: 50px;
    background-color: var(--white);
    border-radius: 5px;
    cursor: pointer;
    transition: 0.5s;
}

.icon:hover {
    background-color: var(--light-grey2);
}

.noBorder {
    border-width: 0;
}

.smallPhotos {
    flex-direction: row;
    display: flex;
}

.imageDisplay {
    display: flex;
    justify-content: center;
}

.imageDisplay img,
.adjustedImage {
    max-width: 70vw;
    max-height: 70vh;
}
