@value variables: "../../../variables.css";
@value mobile-threshold-width from variables;

body .window {
    padding: 60px 50px 30px 50px;
    margin-bottom: 0;
    margin-top: 0;
    max-height: calc(100% - 20px);
}

.window .title {
    margin-bottom: 30px;
    padding: 0;
}

.window .title h2 {
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: var(--dark-grey);
}

.window .content {
    padding-left: 0;
    padding-right: 0;
    position: relative;
    overflow-y: visible;
}

.addLocation {
    font-size: 14px !important;
}

.window .actions {
    display: flex;
    justify-content: flex-end;
    margin-right: 0;
    padding: 0;
    margin-top: 10px;
}

.hrInMobileForm {
    display: none;
}

.textFieldContainer:not(:first-of-type) {
    margin-top: 10px;
}

@media (max-width: mobile-threshold-width) {
    body .window {
        max-height: 100%;
        max-width: 100%;
        height: 100%;
        width: 100%;
        margin: 0px;
        padding: 20px;
    }

    .window .actions {
        padding: 0;
    }

    .window .content {
        overflow-y: visible;
    }

    .window .title {
        text-align: center;
        margin-bottom: 20px;
        align-self: center;
    }

    .window .title h2 {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
    }

    .hrInMobileForm {
        display: block;
        position: absolute;
        top: 50px;
        left: 0;
        border: 1px solid var(--light-grey2);
        width: 100%;
    }

    .noMobileDisplay {
        display: none;
    }

    .window .actions {
        flex-direction: column-reverse;
        padding: 0;
    }
}
