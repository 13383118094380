@value variables: "../../../variables.css";
@value mobile-threshold-width from variables;

body .customButton {
    height: 40px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark-grey);
    letter-spacing: 0.02em;
    text-transform: none;
}

.customButton.primary {
    background-color: var(--light-blue);
    color: var(--white);
    box-shadow: none;
}

.customButton.textPrimary {
    color: var(--light-blue);
    box-shadow: none;
}

body .customButton.primary:hover {
    background: var(--dark-blue);
}

body .customButton:disabled {
    background-color: var(--light-grey2);
    color: var(--mid-tone-grey);
}

.closeButton {
    position: absolute;
    top: 30px;
    right: 30px;
}

.closeButton .icon {
    line-height: 18px;
    transition: 0.4s;
    color: var(--mid-tone-grey);
}

.closeButton .icon:hover {
    cursor: pointer;
    opacity: 0.7;
}

body .cancelButton {
    background: none;
    margin: 0 10px;
}

.icon.mobile {
    display: none;
}

.icon.nomobile {
    display: block;
}

.addButton {
    width: 180px;
}

@media (max-width: mobile-threshold-width) {
    .closeButton {
        position: absolute;
        top: 20px;
        left: 20px;
    }

    .icon.mobile {
        display: block;
    }

    .icon.nomobile {
        display: none;
    }

    body .customButton {
        height: 45px;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
    }

    body .cancelButton {
        width: 100%;
        border: 1px solid var(--dark-grey);
        margin: auto;
        margin-top: 15px;
    }

    body .addButton {
        width: 100%;
        margin: auto !important;
    }
}
