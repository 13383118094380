.container {
    border-radius: 5px;
    background: var(--white);
    box-shadow: var(--effect-style1);
    overflow: auto;
    padding: 10px;
    max-height: calc(100vh - 317px);
}

.submit {
    margin-top: 2rem;
}
