@value variables: "../../../variables.css";
@value mobile-threshold-width from variables;

body .container {
    width: 100%;
    margin-top: 25px;
}

.container .icon {
    height: 18px;
    width: 18px;
    align-items: center;
    filter: invert(64%) sepia(9%) saturate(384%) hue-rotate(180deg)
        brightness(90%) contrast(85%);
    z-index: 1;
}

.container .searchField {
    width: 100%;
}

.filterDrawer {
    display: flex;
    flex-wrap: wrap;
}

.searchField .innerContainer {
    background: var(--white);
    border-radius: 4px;
}

.searchField .innerContainer .outline {
    border: none;
}

.searchField .innerContainer .input {
    height: 40px;
    font-size: 14px;
    padding: 0;
    padding-left: 10px;
}

.container .body {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--light-grey1);
    border-radius: 5px;
    padding-left: 10px;
    background-color: var(--white);
}

.container .header {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: var(--mid-tone-grey);
    margin-bottom: 5px;
}

.button {
    color: var(--light-blue);
}

@media (max-width: mobile-threshold-width) {
    body .container {
        margin-top: 30px;
    }

    .filter {
        display: block;
        margin-right: 11px;
    }
    .filterDrawer {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 20px;
        padding: 10px;
        z-index: 98;
        background-color: white;
        border-radius: 5px;
        z-index: 1001;
    }
    .filterDrawer .checkboxMargin {
        margin: 0;
        margin-bottom: 10px;
    }

    .checkboxMargin:last-of-type {
        margin-bottom: 0;
    }

    .closeWrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 97;
    }
}

@media (max-height: 500px) {
    body .container {
        margin-top: 10px;
    }
}
