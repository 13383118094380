header.whiteHeader {
    color: var(--white);
    margin-left: 20px;
    font-weight: normal;
}

@media (max-width: 610px) {
    header.whiteHeader {
        color: var(--mid-tone-grey);
    }
}
