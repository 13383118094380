.addElement {
    color: var(--light-blue);
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    right: 0;
    display: flex;
    align-items: center;
}

.absolute {
    position: absolute;
}

.normal {
    margin-top: 10px;
}

.plusIcon {
    height: 20px;
    font-weight: normal;
    margin-right: 5px;
}
