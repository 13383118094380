.locationContainer {
    display: flex;
    align-items: center;
}

.locationMarker {
    width: 9px;
    height: 13px;
    margin-left: 12px;
}
