.scrollContainer {
    max-width: calc(100vw - 250px);
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--mid-tone-grey2) var(--light-grey1);
}

.scrollContainer::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.scrollContainer::-webkit-scrollbar-track {
    background: var(--light-grey1);
    border-radius: 3px;
    margin-top: 55px;
}

.scrollContainer::-webkit-scrollbar-track:horizontal {
    background: var(--light-grey1);
    border-radius: 3px;
}

.scrollContainer::-webkit-scrollbar-thumb {
    background: var(--mid-tone-grey2);
    border-radius: 5px;
}

.scrollContainer::-webkit-scrollbar-thumb:horizontal {
    background: var(--mid-tone-grey2);
    border-radius: 5px;
}

.scrollContainer::-webkit-scrollbar-thumb:hover {
    background: var(--mid-tone-grey2);
}
