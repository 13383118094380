.overlayContainer {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    overflow: hidden;
}

.defaultContent {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
}

.overlayIcon {
    width: 14px;
    height: 20px;
}

.overlayHoverContainer {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background-color: #047bebcc;
    border-radius: 4px;
    top: -80px;
    left: 0;
    transition: top 0.2s ease-in-out;
    color: white;
    flex-direction: column;
}

.overlayContainer:hover .overlayHoverContainer {
    top: 0;
}

.previewText {
    width: 100%;
    text-align: center;
}
