@value variables: "../../variables.css";
@value mobile-threshold-width from variables;

.backgroundImage {
    position: relative;
    height: auto;
    width: 50%;
    color: var(--white);
    display: flex;
    font-weight: bold;
    font-size: 25px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.backgroundImage:before {
    width: 100%;
    height: 100%;
    background: url("../../static/images/logo/312e3d25e08522d020d9746879ea25a2.jpeg");
    background-size: auto 100%;
    background-position: center;
    filter: grayscale(100%);
    color: var(--white);
    font-weight: bold;
    font-size: 25px;
    content: "";
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    z-index: -1;
}

.loginForm {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}

.backgroundImage .colorFilter {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--login-img-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 80px;
}

.errorMessage {
    width: fit-content;
    margin: 10px auto;
    text-align: center;
}

@media (max-width: mobile-threshold-width) {
    .backgroundImage {
        display: none;
        width: 0;
    }
}
