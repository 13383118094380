@value variables: "../../variables.css";
@value mobile-threshold-width from variables;

body .root {
    position: relative;
    padding-top: 25px;
}

body .elementDetailsBox {
    max-height: calc(100vh - 178px - 25px);
    overflow: auto;
    width: 100%;
    border-radius: 5px;
}

.container {
    display: flex;
    flex-direction: column;
    margin: 0 auto 25px;
    width: 520px;
}

.textField {
    height: 40px;
    justify-content: center;
    width: 100%;
}

.buttons {
    position: absolute;
    transform: translateY(-100%);
    top: 0;
    right: 0;
    display: flex;
}

.button {
    width: 170px;
    height: 45px;
    display: block;
}

.button.saveButton {
    margin-left: 20px;
}

.button.deleteButton {
    border: 1px solid var(--dark-grey);
    color: var(--dark-grey);
    background: var(--white);
}

@media (max-width: mobile-threshold-width) {
    body .elementDetailsBox {
        box-shadow: none;
    }

    body .root {
        padding-top: 0;
    }

    .buttons {
        position: fixed;
        flex-direction: column-reverse;
        align-items: center;
        width: 100%;
        bottom: 0;
        top: auto;
        background: var(--light-grey3);
        z-index: 10;
        padding-bottom: 30px;
        transform: initial;
    }

    .button {
        width: calc(100vw - 40px);
        height: 45px;
        display: block;
    }

    .button.saveButton {
        margin-left: 0px;
        margin-bottom: 15px;
    }

    .container {
        width: 100%;
        padding-top: 25px;
        margin-top: -15px;
    }
}
