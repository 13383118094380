.select {
    color: var(--dark-grey);
}

.select header {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: var(--mid-tone-grey);
    margin-bottom: 5px;
}

.searchIcon {
    position: absolute;
    height: 18px;
    width: 18px;
    top: calc(50% - 9px);
    left: 15px;
    font-size: 18px;
    color: var(--mid-tone-grey2);
}

.expandIcon {
    position: absolute;
    top: 11px;
    right: 15px;
    font-size: 18px;
    color: var(--light-blue);
}
