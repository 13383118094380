.container {
    font-size: 13px;
    letter-spacing: 0.02em;
    color: var(--mid-tone-grey);
    text-align: left;
    margin-top: 20px;
}

.item {
    display: flex;
    align-items: center;
}

.item span:nth-child(2) {
    font-size: 13px;
    cursor: pointer;
}
