.filter-content {
    display: flex;
    flex-direction: column;
}

.filter-action-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.filter-action-buttons .filter-action-button {
    height: 26px;
    font-size: 10px;
}

.filter-action-buttons .filter-action-button:last-of-type {
    margin-left: 10px;
}
