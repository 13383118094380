.picker {
    width: 100%;
    margin-top: 20px;
}

.noTopMargin {
    margin-top: 0;
}

@media (max-height: 600px) {
    .picker {
        margin-top: 10px;
    }
}
