.alert {
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 4px;
    color: var(--text-alert-color);
    background-color: var(--bg-alert-color);
    border: 1px solid var(--border-alert-color);
}

.alert--incorrect-credentials {
    padding: 0px 15px;
    margin-bottom: 0px;
}

.alert--incorrect-credentials > p {
    margin: 12px 0px;
}
