@value variables: "../../../../../../variables.css";

.statusModal.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.statusModal.options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
}

.statusModal.optionButton {
    font-size: 16px;
}

.icon {
    height: 7px;
    width: 7px;
    border-radius: 3.5px;
    margin-right: 8px;
    border: 1px solid var(--mid-tone-grey2);
    display: inline-block;
}

.icon.open {
    background: var(--light-blue);
    border-color: var(--light-blue);
}

.icon.inReview {
    background: var(--green);
    border-color: var(--green);
}

.icon.inProgress {
    background: var(--white);
    border-color: var(--light-blue);
}

.icon.done {
    background: var(--mid-tone-grey2);
    border-color: var(--mid-tone-grey2);
}
