.row {
    margin-top: 20px;
    margin-right: 15px;
    position: relative;
    width: 80px;
    height: 80px;
    display: inline-block;
}

.imagePreview {
    width: 80px;
    height: 80px;
    border: 1px solid var(--light-grey2);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 3px;
    padding-left: 3px;
    padding-right: 3px;
}

.fileIcon {
    color: var(--light-blue);
}

.noDecoration {
    text-decoration: none;
}

.fileTitle {
    width: calc(80px - 6px);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    letter-spacing: 0.02em;
    color: var(--mid-tone-grey);
    margin: 0;
}

.iconTemplate {
    padding: 20px;
    border-radius: 10px;
}

.imagePreview:hover {
    cursor: pointer;
}

.title {
    width: 100%;
    font-size: 13px;
    letter-spacing: 0.02em;
    color: var(--mid-tone-grey);
    text-align: left;
}

.closeButton {
    position: absolute;
    right: -5px;
    top: -5px;
    width: 20px;
    height: 20px;
    background: var(--light-grey1);
    border-radius: 100%;
}

.closeButton:hover {
    cursor: pointer;
}

.closeButton:before,
.closeButton:after {
    position: absolute;
    left: 9.4px;
    top: 4px;
    content: " ";
    height: 12px;
    width: 1.4px;
    background-color: var(--mid-tone-grey2);
}

.closeButton:before {
    transform: rotate(45deg);
}

.closeButton:after {
    transform: rotate(-45deg);
}
