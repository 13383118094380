@value variables: "../../variables.css";
@value mobile-threshold-width from variables;

.root {
    position: relative;
    padding-top: 25px;
}

.issueDetailsBox {
    width: 100%;
    max-height: calc(100vh - 178px - 25px);
    overflow: auto;
}

.paginationContainer {
    display: flex;
    flex:1;
    justify-content: center;
    gap:15px;
    height: "100%";
    align-items: center;
}


.spacer {
    flex:1;
}

.issueDetailsBox .buttons {
    position: absolute;
    transform: translateY(-100%);
    top: 0;
    right: 0;
    display: flex;
    width: 100%;
    justify-content: center;
}

.rightButtonsContainer {
    flex:1;
    display: flex;
    justify-content: flex-end;
}

.issueDetailsBox .button {
    width: 170px;
    height: 45px;
    display: block;
}

.issueDetailsBox .saveButton {
    margin-left: 20px;
}

.issueDetailsBox .deleteButton {
    border: 1px solid var(--dark-grey);
    color: var(--dark-grey);
    background: var(--white);
}

.issueDetailsBox .issueDetailsBox__row:not(:first-of-type) {
    margin-top: 30px;
}

.issueDetailsBox .issueDetailsBox .textField {
    width: 100%;
    display: flex;
}

.issueDetailsBox .searchIcon {
    color: var(--mid-tone-grey2);
    margin-right: 1em;
    margin-bottom: 4px;
}

.issueDetailsBox .picker {
    margin-top: 1em;
}

.issueDetailsBox .datesInputContainer {
    margin-top: 0;
    width: 30%;
}

.issueDetailsBox .fieldTitle {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.4px;
    color: var(--mid-tone-grey);
    margin-bottom: 8.5px;
    line-height: 14px;
}

.issueDetailsBox .icon {
    display: flex;
    flex-direction: column;
}

.issueDetailsBox .icon .fieldTitle {
    margin-bottom: 15px;
}

.issueDetailsBox-generate-qrcodeBtn {
    margin-top: 30px;
    margin-left: auto;
}

.issueDetails {
    display: flex;
    flex-direction: column;
    width: 520px;
    margin: 0 auto;
}

.issueDetails:last-of-type {
    padding-bottom: 2rem;
}

.issueDetails--icon {
    margin-right: 1em;
    margin-bottom: 4px;
}

.issueDetailsSearch-icon {
    margin-bottom: 4px;
}

.separator {
    border: none;
    height: 1px;
    background: var(--light-grey1);
    margin-top: 25px;
    margin-bottom: 6px;
}

.noIssueContainer {
    max-width: 600px;
    height: 100%;
    margin: 20px auto;
}

@media (max-width: 1260px){
    .paginationContainer p{
        display: none;
    }
}

@media (max-width: 1000px){
    .spacer, .paginationContainer{
        display: none;
    }
}


@media (max-width: mobile-threshold-width) {
    .mobileBackground {
        background-color: var(--white);
        margin: -30px -20px;
        padding: 20px;
    }

    .rightButtonsContainer{
        flex-direction: column;
        gap:15px;
    }

    body .issueDetailsBox {
        box-shadow: none;
    }

    .issueDetailsBox .buttons {
        position: fixed;
        transform: initial;
        width: 100%;
        bottom: 30px;
        top: auto;
        right: auto;
        flex-direction: column-reverse;
        z-index: 1000;
    }

    .issueDetailsBox .button {
        width: calc(100vw - 40px);
        height: 45px;
        display: block;
    }

    .issueDetailsBox .saveButton {
        margin-left: 0px;
        margin-bottom: 15px;
    }

    .issueDetails {
        width: 100%;
        padding: 0;
        margin-top: -15px;
    }

}
