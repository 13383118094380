.navigationItem {
    margin-left: 0px;
    color: var(--white);
    width: 100%;
}

.navigationItem .navLink {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
    padding-left: 25px;
}

.navigationItem .button {
    padding: 0px;
    height: auto;
    width: 100%;
}

.navigationItem a {
    color: var(--black);
    padding-bottom: 12px;
    padding-top: 12px;
}

.navigationItem .active {
    color: var(--white);
    background-color: var(--dark-blue);
}

.navigationItem .navLink:not(.active) {
    filter: invert(21%) sepia(20%) saturate(502%) hue-rotate(176deg)
        brightness(99%) contrast(97%);
}

.navigationItem .navLink:hover {
    filter: none;
}

.navigationItem a:hover,
.navigationItem a:active {
    background-color: var(--dark-blue);
}

@media (max-width: 610px) {
    .navigationItem .navLink:not(.active) {
        filter: none;
    }

    .navigationItem .navLink {
        margin-left: 0px;
        color: var(--dark-grey);
        width: 100%;
    }
    .navigationItem .active,
    .navigationItem a:hover,
    .navigationItem a:active {
        background-color: var(--light-grey1);
    }
}

@media (max-height: 600px) {
    body .navigationItem {
        min-height: 35px;
        max-height: 42px;
    }
    .navigationItem a {
        padding-bottom: 10px;
        padding-top: 10px;
    }
}
