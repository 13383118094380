@value variables: "../../../variables.css";
@value mobile-threshold-width from variables;

.reportForm {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.whiteBackground {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 25px;
    padding-bottom: 24px;
    background-color: var(--white);
    box-shadow: var(--effect-style1);
    border-radius: 5px;
}

.body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 520px;
    width: 90%;
}

@media (max-width: mobile-threshold-width) {
    body .whiteBackground {
        padding: 0;
        box-shadow: none;
        margin-top: 5px;
    }

    .mobileStyleButton {
        position: fixed;
        bottom: 30px;
        width: calc(100vw - 40px);
    }

    .body {
        width: 100%;
    }
}
