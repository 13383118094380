@value variables: "../../../variables.css";
@value first-table-shrink-width from variables;
@value second-table-shrink-width from variables;
@value third-table-shrink-width from variables;
@value mobile-threshold-width from variables;

.appBar {
    height: 100%;
    overflow: hidden;
}

.appBar .logo {
    width: 40px;
    height: 40px;
    padding: 5px;
    float: left;
    margin-left: 25px;
}

.appBar .container {
    height: 100%;
    width: 100%;
    display: inline-table;
    border-radius: 0;
    box-shadow: inset 30px 0px 30px -30px rgba(0, 0, 0, 0.1);
    background: var(--light-grey3);
    padding: 40px 100px 0 100px;
}

@media (max-width: first-table-shrink-width) {
    .appBar .container {
        padding: 40px 70px 0 70px;
    }
}

@media (max-width: second-table-shrink-width) {
    .appBar .container {
        padding: 40px 40px 0 40px;
    }
}

@media (max-width: third-table-shrink-width) {
    .appBar .container {
        padding: 40px 20px 0 20px;
    }
}

.appBar .nav {
    width: 10px;
    background: var(--white);
}

.appBar > table {
    height: 100%;
    width: 100%;
}

.appBar .appBar__tableTopRow {
    height: 60px;
}

.appBar td,
.appBar th {
    border: none;
}

.burgerButton {
    display: none;
}

@media (max-width: mobile-threshold-width) {
    .burgerButton {
        display: block;
    }

    .appBar__tableTopRow,
    .appBar .nav.closed {
        display: none;
    }

    .appBar .nav {
        width: 0;
    }

    .appBar .container {
        padding: 30px 20px;
        box-shadow: none;
        background-color: var(--white);
    }

    .appBar .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.1);
        z-index: 99;
    }
}
